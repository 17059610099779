@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300&display=swap');

.firstNameTxtError{
    color: red;
    visibility: hidden;
}
.Book-Wrapper{
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}
.Book-Wrapper h1{
    text-align: center;
    font-family: 'Anton', sans-serif;
    text-decoration: underline;
    font-size: 7vh;
}
.Book-Wrapper .form-ul{
    margin: 0;
    padding-left: 0;
}
.form-ul-label{
    text-align: center;
}
.form-content{
    padding: 5%;
}
.form-btn {
    width: 20%;
    margin: 0 auto;
    padding-bottom: 5%;
    font-family: 'Anton', sans-serif;
}

@media only screen and (min-width: 650px) {
    .Book-Wrapper{
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
    }
    .Book-Wrapper h1{
        text-align: center;
        font-family: 'Anton', sans-serif;
        text-decoration: underline;
        font-size: 7vh;
        padding: 5%;
    }
    .Book-Wrapper-form{
        width: 65%;
        margin:0 auto;
        /* float: left; */
    }
    
    .Book-Wrapper-map{
        width: 35%;
        float: right;
    }
    .form-btn {
        width:5%;
        margin: 0 auto;
        padding-bottom: 5%;
        font-family: 'Anton', sans-serif;
    }
}