@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300&display=swap');

.Contact-Container{
  background-image: url(../image/contact.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  padding: 10%;
}
.Contact-Content{
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10%;
}
.Contact-Content h2{
  color:crimson;
  font-family: 'Anton', sans-serif;
}
.Contact-Content span{
  color: wheat;
  font-size: 2vh;
}
.Contact-Content h1{
  text-align: center;
  font-family: 'Anton', sans-serif;
  text-decoration: underline;
  font-size: 5vh;
  padding-bottom: 15px;
}