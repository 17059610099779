@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300&display=swap');

.Services-container{
  padding: 10%;
}
.Services-content h1{
  text-align: center;
  font-family: 'Anton', sans-serif;
  text-decoration: underline;
  font-size: 7vh;
  padding-bottom: 15px;
}
.Services-content p{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
  text-align: justify;
}
.Services-content ul{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
}
@media only screen and (min-width: 650px) {
  .Services-container{
    padding: 5% 20% 10% 20%;
  }
  .Services-content h1{
    text-align: center;
    font-family: 'Anton', sans-serif;
    text-decoration: underline;
    font-size: 11vh;
    padding-bottom: 20px;
  }
  .Services-content p{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
    text-align: justify;
    font-weight: 500;
    font-size: 3vh;
  }
  .Services-content ul{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
    font-size: 3vh;
  }
}