@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300&display=swap');

.Fleet-container{
  padding: 10%;
  background-color: black;
}
.Fleet-content h1{
  text-align: center;
  color: white;
  font-family: 'Anton', sans-serif;
  text-decoration: underline;
  font-size: 7vh;
  padding-bottom: 15px;
}
.Fleet-content p{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
  text-align: justify;
  color: ghostwhite;
}
.Fleet-content ul{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
}
.Fleet-content h2{
  color:crimson;
  font-family: 'Anton', sans-serif;
}
@media only screen and (min-width: 650px) {
  .carousel img{
    max-height: 750px;
  }
}