@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@300&display=swap');


.Cover{
  background-image: url(../image/wpg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  padding: 10%;
}
.contact_btn{
  margin-left: 25%;
  padding: 10px;
  margin-top: 5%;
  font-size: 3vh;
}
.cover-content{
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10%;
}
.Yukon{
  /* max-width: 30vh; */
  max-width: 100%;
}
.cover-content h1{
  text-align: center;
  font-family: 'Anton', sans-serif;
  text-decoration: underline;
  font-size: 7vh;
}
.cover-content p{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
  text-align: justify;
}
.cover-content ul{
  font-family: 'Anton', sans-serif;
  font-family: 'Oswald', sans-serif;
}
@media only screen and (min-width: 650px) {
  .Cover{
    background-image: url(../image/wpg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 3%;
  }
  .contact_btn{
    margin-left: 40%;
    padding: 10px;
    font-size: 3vh;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
  }
  .cover-content{
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10%;
  }
  /* .hideHR{
    display: none;
  } */
  .Yukon{
    max-width:100%;
    margin: 0 auto;
  }
  .cover-content h1{
    text-align: center;
    font-family: 'Anton', sans-serif;
    text-decoration: underline;
    font-size: 7vh;
  }
  .cover-content p{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
    text-align: justify;
  }
  .cover-content ul{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
  }
  /* .cover-content-left{
    float: left;
    width: 40%;
  }
  .cover-content-right{
    float: right;
    width: 60%;
  } */
}

@media only screen and (min-width: 1000px) {
  .Cover{
    background-image: url(../image/wpg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 3%;
  }
  .contact_btn{
    margin-left: 40%;
    padding: 10px;
    font-size: 3vh;
    margin-top: 2%;
    margin-bottom: 5%;
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
  }

  .cover-content{
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    height: 85vh;
    padding: 5%;

  }
  /* .hideHR{
    display: none;
  } */
  .Yukon{
    max-width:90%;
    padding-top: 17%;
    margin: 0 auto;
  }
  .cover-content{
    /* padding-top: 12%; */
  }
  .cover-content h1{
    text-align: center;
    font-family: 'Anton', sans-serif;
    text-decoration: underline;
    font-size: 8vh;
  }
  .cover-content p{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
    text-align: justify;
  }
  .cover-content ul{
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
  }
  .cover-content-left{
    float: left;
    width: 60%;
  }
  .cover-content-right{
    float: right;
    width: 40%;
  }
  .cover-content-right h1{
    padding-bottom:5% ;
  }
}