.footer-content h2{
  color:crimson;
  font-family: 'Anton', sans-serif;
  text-align: center;
  font-size: 2vh;
  padding: 10px;
}
.footer-container{
  background-color: black;
  margin-bottom: -7px;
}